import Bugsnag from '@bugsnag/js';
import BugsnagPerformance from '@bugsnag/browser-performance';
import './ck-flyout.js';
import './modal.js';
import './advanced_search_control.js';

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

Bugsnag.start({ apiKey: '8413c28b953c476d94072e77a11e3898' })
BugsnagPerformance.start({ apiKey: '8413c28b953c476d94072e77a11e3898' })

jQuery('.autoselect').live('click', function () {
	jQuery(this).select();
});