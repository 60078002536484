let current_ajax;

function open_cart_flyout() {
	const $cartFlyout = jQuery('#flyout-container');

	$cartFlyout.removeClass('tw-hidden');
	jQuery('body').css('overflow', 'hidden');

	jQuery('.BeaconFabButtonFrame').addClass('tw-hidden');
}

function close_cart_flyout() {
	const $cartContainer = jQuery('#flyout-container');
	$cartContainer.addClass('tw-hidden');
	jQuery('body').css('overflow', 'auto');

	jQuery('.BeaconFabButtonFrame').removeClass('tw-hidden');
}

jQuery('#cart-flyout-icon').on('click', function () {
	initial_cart_load();
	open_cart_flyout();
});

jQuery('#close-cart-flyout').on('click', function () {
	close_cart_flyout();
});

function initial_cart_load() {
	if (current_ajax) {
		current_ajax.abort();
	}

	current_ajax = jQuery.ajax({
		url: '/cart-flyout',
		dataType: 'json',
		data: { ajax: 1 },
		type: 'POST',
		success: function (data) {
			if (data.success != false) {
				cart_flyout(data);
			}
		}
	});
}

const ProductComponent = (data) => {
	return `
		<li class="tw-py-6">
			<div class="tw-flex">
				<div class="tw-size-20 sm:tw-size-32 tw-flex-shrink-0 tw-overflow-hidden tw-rounded-md tw-border tw-border-gray-200">
					<img src="${data.products_image_lrg}" alt="${data.products_name} image" class="tw-h-full tw-w-full tw-object-cover tw-object-center">
				</div>
				<div class="tw-ml-4 tw-flex tw-flex-1 tw-flex-col">
					<div>
						<div class="tw-flex tw-justify-between tw-text-base tw-font-medium tw-text-gray-900">
							<h3>
								<a href="${data.product_url}">
									${data.products_model}
								</a>
							</h3>
							<span class="tw-ml-2">
								${PriceComponent(data)}
							</span>
						</div>
						<p class="tw-mt-1 tw-text-sm tw-text-gray-500">${data.products_name}</p>
					</div>
					<div class="tw-hidden sm:tw-flex sm:tw-flex-1 sm:tw-items-center sm:tw-justify-between sm:tw-text-sm sm:tw-mt-2">
						${QuantityComponent(data)}
						<button type="button" class="delete-product-from-cart tw-font-medium hover:tw-text-indigo-500" data-cart-product-id="${data.cart_product_id}"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="tw-size-5 tw-inline tw-hover-red-500"><path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" /></svg></button>
					</div>
				</div>
			</div>
			<div class="sm:tw-hidden tw-flex smtw-flex-1 tw-items-center tw-justify-between tw-text-sm tw-mt-2">
				${QuantityComponent(data)}
				<button type="button" class="delete-product-from-cart tw-font-medium tw-text-indigo-600 hover:tw-text-indigo-500" data-cart-product-id="${data.cart_product_id}"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="tw-size-5 tw-inline tw-hover-red-500"><path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" /></svg></button>
			</div>
		</li>
	`;
};

const PriceComponent = (data) => {
	return data.product_on_special ? `
			<div>
				<div class="price-original">${data.products_price_original}</div>
				<div class="price-special">${data.products_price_display}</div>
			</div>
		` : `
			<div>${data.products_price_display}<span class="tw-text-xs tw-ml-1">ea</span></div>
	`;
};

const IncludedItemComponent = (data) => {
	// new_product_line_template += `<div class="product_options">`;

	// if (data.product_options) {
	// 	return `
	// 		<div class="product_options">
	// 			${data.product_options.forEach(function (data) {
	// 		new_product_line_template +=
	// 			`<div class="cart-flyout-product">
	// 				<i class="fa fa-times delete-product-from-cart" data-cart-product-id="${data.cart_product_id}"></i>
	// 				<div class="cart-flyout-organize">
	// 					<div class="has-included-items">
	// 						<div class="image-plus-content">
	// 							<a href="${data.product_url}">
	// 								<img class="cart-flyout-product-image" src="${data.products_image}" alt="${data.products_model}">
	// 							</a>
	// 							<div class="cart-flyout-content">
	// 								<a href="${data.product_url}">
	// 									<div class="content-products-model">
	// 										<span style="color:#000000; font-weight:bold;">Option: </span>
	// 										${data.products_model}
	// 									</div>
	// 								</a>
	// 								<div class="content-products-title">${data.products_name}</div>
	// 							</div>
	// 						</div>`;
	// 		if (data.has_included_options) {
	// 			new_product_line_template += `<div class="cart-flyout-included-products">`;
	// 			data.included_options.forEach(function (data) {
	// 				new_product_line_template += `<div class="included-option-note">${data.name} - included</div>`;
	// 			});
	// 			new_product_line_template += `</div>`;
	// 		}
	// 		new_product_line_template += `</div><div>`;
	// 		if (data.product_on_special) {
	// 			new_product_line_template +=
	// 				`<div class="cart-flyout-special-price">
	// 						<div class="price-original">${data.products_price_original}</div>
	// 						<div class="price-special">${data.products_price_display}</div>
	// 					</div>`;
	// 		} else {
	// 			new_product_line_template +=
	// 				`<div>
	// 						<div>${data.products_price_original}</div>
	// 					</div>`;
	// 		}
	// 		new_product_line_template += `</div></div></div>${QuantityComponent(data)}`;
	// 	});
	// }
	// new_product_line_template += `</div></div>`;
}

const IncludedOptionComponent = (data) => {
	if (data.has_included_options) {
		return `
			<div class="cart-flyout-included-products">
				${data.included_options.forEach((data) => `<div class="included-option-note">${data.name} - included</div>`)}
			</div>
		`;
	}

	return '';
};

const QuantityComponent = (data) => {
	return `
		<div class="tw-max-h-18 tw-flex tw-justify-center tw-items-center focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 tw-border tw-rounded tw-max-w-36">
			<button
				class="decrease-quantity tw-px-4 tw-py-2"
				id="quantity-button-number-minus-${data.cart_product_id}"
			>
				<i class="fas fa-minus"></i>
			</button>

			<input
				type="text"
				class="quantity-button-number autoselect tw-text-center tw-rounded-md tw-border-0 tw-py-2 tw-px-1 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-leading-6 tw-text-[16px] tw-w-14"
				id="quantity-button-input-${data.cart_product_id}"
				data-cart-product-id="${data.cart_product_id}"
				data-current-quantity="${data.products_quantity}"
				data-products-id="${data.products_id}"
				value="${data.products_quantity}" />

			<button
				class="increase-quantity tw-px-4 tw-py-2"
				id="quantity-button-number-plus-${data.cart_product_id}"
			>
				<i class="fas fa-plus"></i>
			</button>
		</div>
	`;
};

const EmptyState = () => {
	return `
		<div class="tw-text-center tw-text-lg tw-gray-600 tw-mt-8 tw-p-4">
			<p>You haven't added any items to your cart yet.</p>
		</div>
	`;
};

function cart_flyout(data) {
	let new_product_line_template = ``;

	// start by removing all the items from the flyout. We'll rebuild what we need
	jQuery('#cart-flyout-products').empty();

	if (!data.cart_content) {
		jQuery('#cart-flyout-products').append(EmptyState());
		jQuery('.cart-items-count').hide();
	} else if (data.cart_content.length > 0) { // if we have products then we'll populate the flyout
		jQuery('.cart-items-count').show();

		data.cart_content.forEach(function (data) {
			jQuery('#cart-flyout-products').append(ProductComponent(data));
		});
	}

	jQuery('#cart-flyout-total-cost').html(data.cart_totals.display);
	jQuery('#cart-flyout-total-cost').attr('data-raw-total', data.cart_totals.raw_total);
	// update_shipping_progress_bar(data.cart_totals.raw_total, data.free_shipping_eligible, data.free_shipping_threshold);

	if (new_product_line_template) {
		jQuery('#cart-flyout-products').append(new_product_line_template);
	}

	open_cart_flyout();
}

// function update_shipping_progress_bar(raw_total, eligible = false, free_shipping_threshold) {
// 	if (eligible) {
// 		jQuery('#free-shipping-eligible').show();
// 		jQuery('#free-shipping-not-eligible').hide();
// 		let width = 0;
// 		if (raw_total != null && raw_total > 0) {
// 			width = (raw_total + 1) / (free_shipping_threshold / 100);
// 		}

// 		if (width > 100) {
// 			width = 100;
// 			jQuery('#free-shipping-animation').removeClass('tw-hidden');
// 			jQuery('#progress-bar-title').hide();
// 		}
// 		else {
// 			jQuery('#free-shipping-animation').addClass('tw-hidden');
// 			jQuery('#progress-bar-title').show();
// 		}

// 		jQuery('#shipping-progress-bar').css('width', width + '%');
// 		return;
// 	}
// 	jQuery('#free-shipping-eligible').hide();
// 	jQuery('#free-shipping-not-eligible').hide();
// 	return;
// }

function update_quantity (input_field) {
	if (current_ajax) {
		current_ajax.abort();
	}

	let parent_products_id;
	let option_type;
	if (input_field.attr('data-parent-products-id')) {
		parent_products_id = input_field.attr('data-parent-products-id');
	}

	if (input_field.attr('data-option-type')) {
		option_type = input_field.attr('data-option-type');
	}

	let cart_product_id = input_field.attr('data-cart-product-id');

	let current_product_quantity = input_field.attr('data-current-quantity');
	let user_input_quantity = input_field.val();
	let products_id = input_field.attr('data-products-id');

	if (isNaN(user_input_quantity) || user_input_quantity < 0) {
		input_field.css('background-color', 'red');
		return;
	}

	if (Number(current_product_quantity) != Number(user_input_quantity)) {
		current_ajax = jQuery.ajax({
			url: '/cart-flyout',
			dataType: 'json',
			data: { ajax: 1, cart_product_id: cart_product_id, action:'update-product-quantity', quantity:user_input_quantity, products_id:products_id, parent_products_id:parent_products_id, option_type:option_type },
			type: 'POST',
			success: function (data) {
				if (data.success != false) {
					cart_flyout(data);

					var $inputElement = jQuery(`#quantity-button-input-${cart_product_id}`).parent();

					var previousBorderColor = $inputElement.css('border-color');

					$inputElement
						.css('border-color', '#90EE90');

					setTimeout(function () {
						$inputElement
							.css('border-color', previousBorderColor);
					}, 1000);
				}
			}
		});
	}
}

jQuery('.delete-product-from-cart').live('click', function () {
	jQuery(this).html(`<i class="tw-mt-6 fa-solid fa-spinner fa-spin-pulse"></i>`);

	let cart_product_id = jQuery(this).attr('data-cart-product-id');

	if (current_ajax) {
		current_ajax.abort();
	}

	current_ajax = jQuery.ajax({
		url:'/cart-flyout',
		dataType: 'json',
		data: {
			ajax: 1,
			cart_product_id: cart_product_id,
			action: 'remove-product',
		},
		type: 'POST',
		success: function (data) {
			if (data.success != false) {
				cart_flyout(data);

				if (!jQuery('body').attr('data-admin')) {
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({ ecommerce: null });
					window.dataLayer.push({
						event: 'remove_from_cart',
						ecommerce: {
							currency: 'USD',
							value: data.deleted_product['price_num'] * data.deleted_product['quantity'],
							items: [
								{
									item_id: data.deleted_product['id'],
									item_name: data.deleted_product['name'],
									item_brand: data.deleted_product['manufacturers_name'],
									item_category: data.deleted_product['category_1'],
									item_category2: data.deleted_product['category_2'],
									item_category3: data.deleted_product['category_3'],
									price: data.deleted_product['price_num'],
									quantity: data.deleted_product['quantity'],
								},
							],
						},
					});
				}
			}
		}
	});
});

function debounce(func, wait) {
	let timeout;

	return function () {
		const context = this, args = arguments;

		clearTimeout(timeout);

		timeout = setTimeout(function () {
			func.apply(context, args);
		}, wait);
	};
}

window.add_product_to_cart = function() {
	ck.ajaxify.form(jQuery('.add-to-cart-form'), function(data) {
			if (data.success) {
				cart_flyout(data);

				// klaviyo track adding product. This is duplicated due to scope issues
				// that we didn't have time to explore, just need a fix.
				let cart = data;
				var atc = {
					'$value': cart.cart_totals.raw_total,
					'ItemNames': cart.cart_content.map(function(item) { return item.products_name; }),
					'CheckoutURL': cart.fqdn + '/checkout_shipping.php',
					'Items': cart.cart_content.map(function(item) {
						return {
							'ProductID': item.products_id,
							'SKU': item.products_model,
							'ProductName': item.products_name,
							'Quantity': item.products_quantity,
							'ItemPrice': item.products_price_display,
							'RowTotal': item.line_subtotal,
							'ProductURL': cart.fqdn + item.product_url,
							'ImageURL': cart.cdn + item.products_image_lrg,
						};
					}),
				}

				// there's apparently no provision to specify what was removed from the cart, or if it was just a qty update
				// so we just include the added item fields if we have that info, otherwise we just send the cart contents
				if (cart.added_product != undefined) {
					atc['AddedItem_ProductName'] = cart.added_product['products_name'];
					atc['AddedItem_ProductID'] = cart.added_product['products_id'];
					atc['AddedItem_SKU'] = cart.added_product['products_model'];
					atc['AddedItem_ImageURL'] = cart.added_product['image'];
					atc['AddedItem_URL'] = cart.added_product['url'];
					atc['AddedItem_Price'] = cart.added_product['price'];
					atc['AddedItem_Quantity'] = cart.added_product['quantity'];
				};

				_learnq.push(['track', 'Added to Cart', atc]);

				if (window.geq) {
					window.geq.addToCart(atc);
				}

				if (!jQuery('body').attr('data-admin')) {
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({ ecommerce: null });
					window.dataLayer.push({
						event: 'add_to_cart',
						ecommerce: {
							currency: 'USD',
							value: cart.added_product['price'] * cart.added_product['quantity'],
							items: [
								{
									item_id: cart.added_product['products_id'],
									item_name: cart.added_product['item_name'],
									item_brand: cart.added_product['item_brand'],
									item_category: cart.added_product['category_1'],
									item_category2: cart.added_product['category_2'],
									item_category3: cart.added_product['category_3'],
									price: cart.added_product['price'],
									quantity: cart.added_product['quantity'],
								},
							],
						},
					});
				}
			}
		},
		function(jqXHR, textStatus, errorThrown) {
			if (textStatus == 'abort') {
				jQuery('.main-body-inner-container').animate({ backgroundColor: 'transparent' }, 500, 'swing');
				return;
			}
			jQuery('#add-to-cart-result').addClass('failure').html('There was a problem adding this item to your cart.');
		},
		function() {
			jQuery('#add-to-cart-result').removeClass('success').removeClass('failure');
	});
}

window.add_product_to_cart();

jQuery('#ck-new-search-bar-button').on('click', function () {
	jQuery('#ck-new-mobile-search-bar-container').slideToggle();
	jQuery('#ck-new-mobile-search-bar-container input').focus();
});

jQuery('.slide-down-button').on('mouseover', function () {
	let slide_down = jQuery(this).data('type');
	jQuery('#'+slide_down+'-slide-down').slideDown('fast');
});

jQuery('.slide-down-container').on('mouseleave', function () {
	let slide_down = jQuery(this).data('type');
	jQuery('#'+slide_down+'-slide-down').slideUp('fast');
});

jQuery('.menu-button').on('click', function(){
	jQuery('html').toggleClass('nav_visible');
	jQuery('#ck-new-mobile-search-bar-container').hide();
});

jQuery('#flyout-overlay').on('click', () => close_cart_flyout());

/**
 * Update quantity for products in the cart
 */

jQuery('.quantity-button-number').live('keyup', debounce(function (e) {
	// we are going to get the enter key somewhere else
	if (e.which === 13 || e.key === 'Enter') {
		return;
	}

	update_quantity(jQuery(this));
}, 750));

jQuery('.quantity-button-number').live('keypress', function (e) {
	if (e.which === 13 || e.key === 'Enter') {
		update_quantity(jQuery(this));
	}
});

jQuery('.increase-quantity').live('click', function () {
	const $input = jQuery(this).siblings('.quantity-button-number');
	let currentQuantity = parseInt($input.val());

	$input.val(currentQuantity + 1);

	update_quantity($input);
});

jQuery('.decrease-quantity').live('click', function () {
	const $input = jQuery(this).siblings('.quantity-button-number');
	let currentQuantity = parseInt($input.val());

	$input.val(currentQuantity - 1);

	update_quantity($input);
});
